import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Typography, Container } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {},
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em",
    },
    marginTop: "10em",
  },
  linksStyles: {
    textDecoration: "underline",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: "#291C64"
    }
  },
}))

export default function Index() {
  const classes = useStyles()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CFL | Quejas</title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} py={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>Nuestra política de quejas</b>
            </Typography>
          </Box>
          <Box mt={4} py={2}>
            {/* <Typography variant="body1" gutterBottom inline>
              Si no está satisfecho con algún aspecto de nuestro servicio, en primer lugar, comuníquese con CFL al <a href="tel:+525585265645" className={classes.linksStyles}><b>+52 55 85265645</b> </a> o{" "}
              <b>quejas@capitalfundinglab.com</b>. Realizaremos inmediatamente una investigación independiente de su queja y le proporcionaremos una respuesta por escrito.
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Si no podemos resolver su queja dentro de los tres días hábiles, remitiremos su queja a nuestra firma principal, Resolution Compliance Limited, para completar y comunicarle el resultado de la investigación.
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Si no podemos resolver su queja a su satisfacción y usted es un demandante elegible según lo definido por la Autoridad de Conducta Financiera, tendrá que recurrir al Servicio del Defensor Financiero. Los detalles del Servicio del Defensor Financiero son:
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Por esto:
            </Typography> */}
            <Typography variant="body1" gutterBottom inline>
              En caso de que el resultado de su queja no sea el esperado, puede ingresar una apelación a la Unidad Especializada de Atención de Usuarios (UNE), quien le brindara atención a solicitud al correo <a href="mail:UNE@capitalfundinglab.com" className={classes.linksStyles}><b>UNE@capitalfundinglab.com</b> </a>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
